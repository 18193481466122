@import '@/assets/styles/global.scss';

.Private {
  background-color: #f6f6f6;
  min-height: 100vh;
  &-content {
    padding: 0 15px 30px;
    max-width: 1440px;
    width: 100%;
    margin: 20px auto 0;
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;
  }
}

@import '@/assets/styles/global.scss';

.Empty {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #687283;
    text-align: center;
  }

  &-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3499e6;
    margin-bottom: 5px;
    &_dark {
      color: #171e2a;
    }
  }

  &-icon {
    background: rgba(52, 153, 230, 0.1);
    padding: 37px;
    width: 130px;
    height: 130px;
    border-radius: 40px;
    margin-bottom: 25px;
  }
  &-btn {
    margin-top: 15px;
  }
}

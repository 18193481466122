@import '@/assets/styles/global.scss';

.VegetationIndices {
  width: 100%;
  max-width: 190px;
  position: absolute;
  top: 15px;
  left: 10px;
  padding: 15px;
  color: #171e2a;
  background-color: $white;
  border-radius: $border-radius-lg;
  z-index: 50;

  $self: &;

  &-top {
    display: flex;
    justify-content: space-between;
  }

  &-title {
    font-size: $font-size-md;
    font-weight: $font-weight-600;
  }

  &-arr {
    padding: 0;
    margin-left: 15px;
  }

  &-icon {
    @extend %transition;

    &_open {
      transform: rotate(-180deg);
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-btn {
    padding: 10px 5px;
    font-weight: $font-weight-600;
    font-size: $font-size-sm;
    line-height: 12px;
    &_active {
      background-color: rgba(#3499e6, 0.1);
      color: #3499e6;
      border-radius: $border-radius-sm;
    }
  }

  &-content {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 15px;
  }

  &-colors {
    background-color: #ebf5fc;
    border-radius: 10px;
    padding: 10px;
    max-width: 110px;
    width: 100%;
  }

  &-item {
    // margin-bottom: 8px;
    display: flex;
    // align-items: center;

    &_margin {
      margin-bottom: 8px;
    }

    &:last-child {
      #{$self} {
        &-value {
          margin-top: 0;
        }
      }
    }
  }

  &-square {
    width: 13px;
    height: 13px;
    border-radius: 3px;
  }

  &-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #687283;
    margin-top: 10px;
  }

  &-value {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #171e2a;
    margin-left: 4px;
    margin-top: 11px;
    &_opacity {
      opacity: 0;
    }

    &_none {
      margin-top: 0;
    }
  }
}

@import '@/assets/styles/global.scss';

.TaskForm {
  margin-top: 25px;
  &-title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #171e2a;
    &_none {
      margin: 0;
    }
  }

  &-top {
    margin-bottom: 10px;
  }

  &-desc {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 160%;
    color: #687283;
  }

  &-step {
    margin-bottom: 25px;
    position: relative;
  }
  &-user {
    margin-bottom: 10px;
  }
  &-btn {
    padding: 10px 5px;
    font-weight: $font-weight-600;
    font-size: $font-size-sm;
    line-height: 12px;
    &_active {
      background-color: rgba(#3499e6, 0.1);
      color: #3499e6;
      border-radius: $border-radius-sm;
    }
  }
}

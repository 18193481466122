@import '@/assets/styles/global.scss';

.Chat {
  width: 100%;
  padding: 15px 20px 20px;
  $self: &;

  &_default {
    #{$self} {
      &-wrap {
        height: calc(100vh - 315px);
      }
    }
  }

  &_component {
    border-radius: 0;
    box-shadow: none;

    #{$self} {
      &-wrap {
        height: calc(100vh - 215px);
      }
    }
  }

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #171e2a;
    margin-bottom: 15px;
  }

  &-wrap {
    display: flex;
    flex-direction: column;
  }

  &-item {
    margin-bottom: 10px;
    padding: 20px;
    background-color: #f0f4fc;
    border-radius: 10px;
    max-width: 95%;

    &_you {
      background-color: #f9fcf0;
      margin-left: auto;
      #{$self} {
        &-text {
          color: #6f7050;
        }

        &-date {
          color: rgba(111, 112, 80, 0.6);
        }
      }
    }
  }

  &-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #505b70;
    margin-bottom: 10px;
  }

  &-date {
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    color: rgba(80, 91, 112, 0.6);
  }

  &-photos {
    margin-bottom: 10px;
  }

  &-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  &-add {
    min-width: 53px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: rgba(52, 153, 230, 0.1);
    border-radius: 10px;
    margin-left: auto;
  }

  &-textarea {
    padding: 12px 15px;
    background-color: #f7f7f7;
    border-radius: 5px;
    width: 100%;
    max-width: calc(100% - 63px);
    min-height: 53px;
    resize: vertical;
  }

  // &-scrollbar {
  //   width: 100%;

  // }

  &-user {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #171e2a;
    svg {
      margin-right: 10px;
    }
  }

  &-loading {
    padding: 20px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &_full {
      min-height: 100%;
    }
  }

  &-form {
    position: relative;
  }

  &-file {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: -99999;
  }
}

@import '@/assets/styles/global.scss';

.FieldImage {
  width: 66px;
  height: 66px;
  background-color: #ffffff;
  border-radius: 12px;
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

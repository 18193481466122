@import '@/assets/styles/global.scss';

.Header {
  display: flex;
  padding: 19px 0;
  width: 100%;
  height: 54px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);

  &-line {
    display: flex;
    align-items: center;
    padding: 0 15px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    @include mobile {
      justify-content: center;
    }
  }

  &-menu {
    margin-left: 60px;

    @include mobile {
      display: none;
    }
  }
}

@import '@/assets/styles/global.scss';

.Info {
  padding: 10px 20px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #3499e6;

  svg {
    margin-right: 10px;
  }
}

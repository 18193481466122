@import '@/assets/styles/global.scss';

.Button {
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  min-height: 40px;
  padding: 5px 15px;
  border-radius: 5px;
  position: relative;
  $self: &;
  @extend %transition;

  svg {
    path {
      @extend %transition;
    }
  }

  // &:hover {
  //   // background-color: $dark-orange;
  //   // border: 1px solid $dark-orange;
  // }

  // &:active {
  //   background-color: $dark-orange;
  //   box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  // }

  // &[disabled] {
  //   pointer-events: none;
  //   background-color: $secondary;
  //   border: 1px solid $secondary;
  // }

  &_dark {
    color: $white;
    background-color: #171e2a;

    &:hover {
      background-color: #3499e6;
    }

    &[disabled] {
      color: #b2b6bf;
      background: #e1e4e9;
    }
  }

  &_outline {
    color: #3499e6;
    border: 1px solid #eeeeee;
    &:hover {
      color: #171e2a;
    }

    &[disabled] {
      color: #b2b6bf;
      border: 1px solid #eeeeee;
    }
  }

  &_red {
    border: 1px solid #eeeeee;
    color: #e24a4a;
    &:hover {
      color: #171e2a;
    }

    &[disabled] {
      color: #b2b6bf;
      border: 1px solid #eeeeee;
    }
  }

  &_fluid {
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -11px;
    width: 20px;
    height: 20px;
    border: 4px solid;
    border-left-color: transparent;
    border-radius: 50%;
    opacity: 0;
    transition-duration: 0.5s;
    transition-property: opacity;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: rotate;
    animation-timing-function: linear;
  }

  &_loading {
    &::before {
      opacity: 1;
    }
  }

  &-icon {
    &_left {
      margin-right: 12px;
    }
    &_right {
      margin-left: 12px;
    }
  }

  &_ios {
    font-size: 16px;
    line-height: 16px;
    min-height: 50px;
  }
}

// Animations
//**********************//

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

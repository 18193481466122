@import '@/assets/styles/global.scss';

.Paginator {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background-color: #ffffff;
  border-radius: 10px;

  &_padding {
    padding: 6px 13px;
  }

  &-btn {
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #171e2a;
    padding: 2px 10px;
    min-height: 30px;
    width: 100%;
    @extend %transition;
    &_active {
      background-color: rgba(#3499e6, 0.1);
      color: #3499e6;
    }
    &_padding {
      padding: 10px 55px;
    }
  }
}

@import '@/assets/styles/global.scss';

.Top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  &-btn {
    padding: 0;
  }
}

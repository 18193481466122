// Main color
$primary: #ed682b !default;
$dark-orange: #9a3a0e !default;
$text-menu: #4a5056 !default;
$secondary: #9194a7 !default;
$orange-2: #f3ae71 !default;
$green: #6fcf97 !default;
$blue-dark: #002b53 !default;
$blue: #376295 !default;
$white: #fff !default;
$gray: #f2f2f5 !default;
$light-gray: #d3d3db !default;
$black: #000 !default;
$dark: #242b35 !default;
$dark-100: #002b53 !default;

$light-red: #de6f6c !default;
$red: #eb5757 !default;
$orange: #ed682b !default;
//$blue: #05a4e1 !default;
//$blue-dark: #4182e6 !default;
$blue-light: #75a5ed !default;
$blue-100: #4aa4e1 !default;
$blue-200: #5793ef !default;
$blue-300: #3367b6 !default;
$blue-400: #3d6fb7 !default;
$blue-500: #6994c1 !default;
$brown: #e2c375 !default;
$dark-gray: #949595 !default;
$gray-100: #ebedef !default;
$gray-200: #f6f6f6 !default;
$gray-300: #e3f2fe !default;
$gray-400: #f2f2f2 !default;
$gray-500: #e0e0e0 !default;
$gray-600: #e6e6e6 !default;
$gray-700: #e9e9ee !default;
$gray-800: #f5f5f6 !default;
$gray-900: #f8f8f8 !default;
$gray-1000: #f7f7f8 !default;
$gray-1100: #4f4f4f !default;
$gray-1200: #646675 !default;
$purple: #a398ee !default;
$light-green: #6fcf97 !default;
$green: #219653 !default;
$green-100: #27ae60 !default;
$text-color: #4a5056 !default;
$secondary: #9194a7 !default;
$body-bg: $white;

// Typography
// Font, line-height, and color for body text, headings, and more.

$default-font: 'Montserrat', sans-serif !default;
$font-family-base: $default-font !default;

$font-size-base: 16px !default;
$font-size-xsm: 10px !default;
$font-size-sm: 12px !default;
$font-size-md: 14px !default;
$font-size-lg: 18px !default;
$font-size-xlg: 20px !default;
$font-size-required-before: 23.1361px !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 100 !default;
$font-weight-200: 200 !default;
$font-weight-300: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-600: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-800: 800 !default;
$font-weight-900: 900 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.225 !default;
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 2px !default;
$border-color: $white !default;

$border-radius: 3px !default;
$border-radius-sm: 5px !default;
$border-radius-md: 8px !default;
$border-radius-lg: 12px !default;
$border-radius-xlg: 25px !default;

$gap: 15px;

.Message {
  cursor: pointer;
  &_obb {
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
  }
  &-farm {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #3499e6;
    margin-bottom: 8px;
  }

  &-field {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #3499e6;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      opacity: 0.4;
    }
  }

  &-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  &-info {
    display: flex;
    align-items: center;
  }

  &-date {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #687283;
    margin-right: 10px;
  }

  &-link {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #3499e6;
  }

  &-assignee {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #171e2a;
    margin-top: 8px;
  }
}

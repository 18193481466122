@import '@/assets/styles/global.scss';

.LineChart {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;

  &-wrap {
    width: 100%;
    height: 250px;
  }
}

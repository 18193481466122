@import '@/assets/styles/global.scss';

.DatePicker {
  $self: &;
  position: relative;
  display: flex;

  &_xxl {
    margin-bottom: 40px;
  }
  &_xl {
    margin-bottom: 30px;
  }
  &_lg {
    margin-bottom: 25px;
  }
  &_md {
    margin-bottom: 20px;
  }
  &_sm {
    margin-bottom: 14px;
  }

  &_none {
    margin-bottom: 0;
  }

  &_top {
    align-items: flex-start;
  }

  &_bottom {
    align-items: flex-end;
  }

  &_middle {
    align-items: center;
  }

  &-wrap {
    position: relative;
    line-height: 1;
    display: flex;
    width: 100%;
  }

  &-wrapper {
    line-height: 1;
  }

  &-popper {
    z-index: 2;
    width: 100%;
    &_fluid {
      width: 100%;
    }
  }

  &-label {
    display: flex;
    margin-bottom: 5px;
    font-weight: $font-weight-medium;
    font-size: $font-size-sm;
    line-height: 15px;

    &_required {
      position: relative;
      padding-left: 15px;
      &::before {
        position: absolute;
        left: 3px;
        top: -2px;
        content: '*';
        font-weight: normal;
        font-size: $font-size-required-before;
        line-height: 23px;
        letter-spacing: 0.0015em;
        // color: #3499e6;
      }
    }
  }

  &-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: $font-size-md;
    line-height: 1;
    &_click {
      cursor: pointer;
    }
    &_left {
      left: 12px;
    }
    &_right {
      right: 12px;
    }
  }

  &-input {
    width: 100%;
    min-height: 42px;
    padding: 5px 15px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    font-family: 'Montserrat', sans-serif;
    @extend %transition;
    color: #687283;
    &_white {
      background-color: $white;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    }
    &_default {
      background-color: #f7f7f7;
    }

    &_gray {
      background-color: #f7f7f7;
    }

    // &:focus {
    //   border-color: $text-menu;
    // }

    &::placeholder {
      color: #687283;
    }

    &[readonly] {
      &:focus {
        border-color: $light-gray;
      }
    }

    &[disabled] {
      box-shadow: none;
      background: rgba(255, 255, 255, 0.5);
      border-color: $gray-900;
    }

    &_error {
      border: 1.5px solid $red;
      &:focus {
        border: 1.5px solid $red;
      }
    }

    &_left {
      padding-left: 43px;
    }
    &_right {
      padding-right: 43px;
    }

    &_xxl {
      min-height: 66px;
    }

    &_xl {
      min-height: 60px;
    }

    &_lg {
      min-height: 50px;
      font-size: $font-size-lg;
    }

    &_md {
      min-height: 40px;
    }

    &_sm {
      min-height: 32px;
    }

    &_errors {
      color: #e24a4a;
    }

    &_ios {
      font-size: 16px;
      line-height: 16px;
      min-height: 50px;
    }
  }

  &-error {
    position: absolute;
    bottom: -22px;
    transition: all 0.5s ease;
    color: $red;
    font-size: $font-size-xsm;

    &.entering,
    &.entered {
      animation: slideError 0.5s ease-out forwards;
    }

    &.exiting {
      animation: slideError 0.5s ease-in alternate-reverse;
    }

    &.exited {
      opacity: 0;
    }
  }

  &-calendar {
    position: absolute;
    top: 10px;
    min-width: 250px;
    width: 100%;
    border: none;
    border-radius: 16px;
    font-size: 14px;
    font-family: 'Montserrat';
    color: $dark-100;
    padding: 10px;
    box-shadow: 8px 3px 22px 10px rgba(150, 150, 150, 0.11);
    &_fluid {
      width: 100%;

      .react-datepicker__month-container {
        float: none;
        width: 100%;
      }
      .react-datepicker__day-names,
      .react-datepicker__week {
        display: flex;
      }
      .react-datepicker__day-names {
        margin: 0 0.4rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .react-datepicker__day-name,
      .react-datepicker__day {
        width: 100%;
      }
    }

    .react-datepicker {
      &__day--keyboard-selected,
      &__month-text--keyboard-selected,
      &__quarter-text--keyboard-selected,
      &__year-text--keyboard-selected {
        background-color: #3499e6;
        color: $white;
      }
      &__header {
        background-color: $white;
        border-radius: 16px 16px 0 0;
        border-bottom: none;
        padding-bottom: 20px;
      }
      &__current {
        &-month {
          color: #171e2a;
          font-size: $font-size-md;
          font-weight: $font-weight-600;

          &::after {
            content: '';
            display: block;
            height: 0.8px;
            background: #eaeaea;
            margin: 20px auto;
            width: 95%;
          }
        }
      }
      &__day {
        &-name {
          font-weight: $font-weight-600;
          color: #3499e6;
          font-size: 10px;
        }
      }

      &__navigation {
        &-icon {
          &::before {
            border-color: #41516d;
            top: 15px;
            border-width: 2px 2px 0 0;
            height: 7px;
            width: 7px;
          }
        }
      }

      &__triangle {
        display: none;
      }

      &__day {
        border-radius: 50%;
        font-weight: $font-weight-medium;
        color: $dark;
        &:hover {
          background-color: #3499e6;
          color: $white;
        }

        // &--today {
        //   color: $white;
        // }

        &--selected {
          color: $white;
          background-color: #3499e6;
          border-radius: 50%;
          &:hover {
            background-color: $dark-orange;
            color: $white;
          }
        }
        &--outside {
          &-month {
            color: $light-gray;
          }
        }

        &--disabled {
          background-color: transparent;
          color: $light-gray;
          &:hover {
            background-color: transparent;
            color: $light-gray;
          }
        }
      }

      &-time {
        &__header {
          color: $white;
          font-weight: $font-weight-600;
        }
      }

      &__time {
        &-name {
          color: $dark;
          font-weight: $font-weight-normal;
        }
        &-list {
          &-item {
            font-weight: $font-weight-normal;
            color: $dark;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: #3499e6;
  }
  .react-datepicker__input-container {
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__day-names {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
  }
}

@keyframes slideError {
  0% {
    transform: translateX(30px);

    opacity: 0;
  }

  100% {
    transform: translateX(0);

    opacity: 1;
  }
}

@import '@/assets/styles/global.scss';

.FieldView {
  &-top {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  &-content {
    width: 100%;
  }

  &-chat {
    max-width: 356px;
  }

  &-field {
    display: flex;
    align-items: center;
  }

  &-info {
    margin: 0 15px 0 10px;
  }

  &-group {
    margin-bottom: 25px;
    &_flex {
      display: flex;
      justify-content: space-between;
    }
    &_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      @include widescreen {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &-name {
    color: #171e2a;
    font-size: 22px;
    font-weight: 600;
    line-height: 22px;
  }

  &-hectares {
    color: #687283;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    margin-top: 6px;
  }

  &-head {
    margin-bottom: 10px;
  }
  &-tasks {
    margin: 15px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  &-task {
    padding: 15px;
    background-color: $white;
    border-radius: 10px;
  }

  &-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3499e6;
  }

  &-text {
    margin-top: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #687283;
    margin-bottom: 4px;
  }

  // &-wrap {
  //   display: flex;
  //   align-items: center;
  //   margin-top: 5px;
  // }

  &-date {
    font-weight: 600;
    font-size: 11px;
    line-height: 160%;
    color: #687283;
    margin-right: 10px;
  }

  &-circle {
    background-color: #171e2a;
    border: 2px solid #ffffff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
    font-weight: 600;
    font-size: 8px;
    line-height: 8px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-usename {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #3499e6;
  }
  &-user {
    display: flex;
    align-items: center;
    &:last-child {
      margin-top: 15px;
    }
  }

  &-priority {
    display: flex;
    align-items: center;
    &:last-child {
      margin-top: 15px;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 10px;

    &_five {
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }

    &_task {
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;

      @include widescreen {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mobile {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &-mapbox {
    border-radius: 10px;
    height: 180px;
    min-height: auto;
    // overflow: hidden;

    .mapboxgl-map {
      height: 100% !important;
    }

    &_fullscreen {
      min-height: calc(100vh - 54px - 20px - 66px - 25px - 30px);
    }
  }

  &-pager {
    margin-bottom: 30px;
  }
}

@import '@/assets/styles/global.scss';

.WeatherDay {
  padding: 20px;
  background-color: #3499e6;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @include xmobile {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  &-temp {
    font-weight: 500;
    font-size: 26px;
    line-height: 26px;
    color: #ffffff;

    &_small {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      margin-top: 5px;
    }
  }

  &-square {
    min-height: 50px;
    padding: 5px 12px;
    background-color: #1c88da;
    border-radius: 10px;
    display: flex;
    align-items: center;
    &_outline {
      border: 1px solid #1c88da;
      background-color: transparent;
    }
  }
  &-icon {
    margin-right: 14px;
  }

  &-svg {
    width: 40px;
    height: 40px;
    path {
      fill: $white;
    }
  }

  &-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    &:last-child {
      font-weight: 500;
      line-height: 12px;
      margin-top: 5px;
    }
  }

  &-item {
    display: flex;
    align-items: center;
  }
}

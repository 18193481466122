@import '@/assets/styles/global.scss';

.Paginate {
  margin-top: 20px;
  &-list {
    display: flex;
    align-items: center;
  }

  &-item {
    margin-right: 8px;

    &:last-child {
      margin: 0;
    }
  }

  &-link {
    border-radius: 6px;
    padding: 6px 9px;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    @extend %transition;
    color: #171e2a;
    &:hover,
    &_active {
      background: rgba(#3499e6, 0.1);
      color: #3499e6;
    }
  }
}

@import '@/assets/styles/global.scss';

.Culture {
  display: flex;
  align-items: center;

  &-icon {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    &_small {
      width: 28px;
      height: 28px;
    }
  }
  &-crop {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    color: #171e2a;
    margin-bottom: 3px;
  }
  &-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.2;
    color: #687283;
  }
}

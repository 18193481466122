@import '@/assets/styles/global.scss';

.Scouting {
  display: flex;
  justify-content: space-between;

  &-left {
    width: 100%;
    max-width: calc(40% - 15px);
    &_center {
      margin: auto;
    }
  }

  &-right {
    width: 100%;
    max-width: calc(60% - 15px);
    border-radius: 10px;

    .mapboxgl-map {
      min-height: calc(100vh - 54px - 20px - 66px - 25px - 30px);
    }
  }

  &-head {
    margin-bottom: 0;
    width: 100%;
  }

  &-title {
    margin: 0;
  }

  &-tabs {
    margin-left: 15px;
    width: 100%;
    max-width: 250px;
    white-space: nowrap;
  }

  &-task {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &-description {
    top: 120px;
    left: 10px;
  }

  &-indices {
    left: 50px;
    top: 10px;
  }
  &-empty {
    background-color: #f7f7f7;
    display: flex;
  }
  &-btn {
    padding: 13px 61px;
    font-family: 'Montserrat';
  }
}

@import '@/assets/styles/global.scss';

.Task {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  &_review {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &-right {
    max-width: 205px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  &-btn {
    grid-column: 1/-1;
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  &-actions {
    display: flex;
    align-items: center;
  }
  &-action {
    padding: 0;

    &:last-child {
      margin-left: 25px;
    }
  }

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #171e2a;
    margin-right: 10px;
  }

  &-desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #687283;
    margin-bottom: 5px;
  }

  &-wrap {
    display: flex;
    align-items: center;
    &:last-child {
      margin-top: 15px;
    }
  }

  &-date {
    font-weight: 600;
    font-size: 11px;
    line-height: 160%;
    color: #687283;
    margin-right: 30px;
  }

  &-circle {
    background-color: #171e2a;
    border: 2px solid #ffffff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
    font-weight: 600;
    font-size: 8px;
    line-height: 8px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-usename {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #3499e6;
  }

  &-type {
    font-weight: 600;
    font-size: 10px;
    line-height: 110%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 8px;

    border-radius: 14px;

    &_critical {
      color: #e24a4a;
      background: rgba(226, 74, 74, 0.2);
    }

    &_normal {
      color: #41a51e;
      background: rgba(104, 226, 74, 0.2);
    }
  }
}

@import '@/assets/styles/global.scss';

.CurrentWeather {
  padding: 10px 12px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  &-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    &_large {
      font-size: 26px;
      line-height: 26px;
    }
  }
  &-key {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #687283;
    margin-top: 5px;
  }
}

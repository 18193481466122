@import '@/assets/styles/global.scss';

.Status {
  display: inline-flex;
  font-weight: 600;
  font-size: 10px;
  line-height: 110%;
  padding: 4px 8px;
  height: 19px;
  border-radius: 14px;

  &_critical {
    background: rgba(226, 74, 74, 0.2);
    color: #e24a4a;
  }
  &_high {
    background: rgba(237, 152, 25, 0.2);
    color: #a7720b;
  }
  &_low {
    background: rgba(74, 135, 226, 0.2);
    color: #1c6fbb;
  }
  &_normal {
    background: rgba(50, 214, 86, 0.2);
    color: #0da02d;
  }
}

@import '@/assets/styles/global.scss';

.MapBox {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  &-spinner {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-ctrl {
    &-logo,
    &-attrib {
      display: none;
    }
    &-top {
      &-left {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
      }
    }
    &-geocoder {
      position: relative;

      &--input {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #687283;
        padding: 12px 25px 12px 35px;
        height: 42px;
        background: #ffffff;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        width: 290px;
        font-family: 'Montserrat';
      }

      &--icon {
        &-search {
          position: absolute;
          left: 10px;
          top: 12px;

          path {
            fill: #687283;
          }
        }

        &-loading {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: none !important;
        }
      }

      &--button {
        padding: 0;
        line-height: 1;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        svg {
          width: 14px;
          height: 14px;
          path {
            fill: #687283;
          }
        }
      }

      &--pin {
        &-right {
          position: absolute;
          right: 0;
          top: 0;
          height: 42px;
          width: 25px;
        }
      }

      &--powered-by {
        display: none !important;
      }

      &--suggestion {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  .suggestions {
    li {
      padding: 5px 10px;
      cursor: pointer;
      border-bottom: 1px solid #eeeeee;

      &:last-child {
        border: none;
      }

      a {
        cursor: pointer;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.2;
        color: #687283;
        &:hover,
        .active {
          color: #3499e6;
        }
      }
    }
    &-wrapper {
      background-color: #fff;
      max-width: 290px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      margin-top: 5px;
    }
  }
}

@import '@/assets/styles/global.scss';

.EditTask {
  $self: &;
  &-content {
    display: flex;
    flex: 1 1 100%;
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    padding: 25px;
    height: 100%;
  }

  &-title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #171e2a;
    &_none {
      margin: 0;
    }
  }

  &-top {
    margin-bottom: 10px;
  }

  &-desc {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 160%;
    color: #687283;
  }

  &-step {
    margin-bottom: 25px;
    position: relative;
  }

  &-mapbox {
    position: relative;
    border-radius: 10px;
  }
}

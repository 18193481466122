@import '@/assets/styles/global.scss';

.Accordion {
  padding: 20px;
  border: 1px solid rgba(104, 114, 131, 0.3);
  border-radius: 20px;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #171e2a;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 16px;
    height: 14px;
  }

  &-content {
    margin-top: 30px;
  }
}

@import '@/assets/styles/global.scss';

.Chats {
  &-wrap {
    display: flex;
  }

  &-sidebar {
    width: 100%;
    max-width: 356px;
  }

  &-paginator {
    width: 100%;
    margin-bottom: 25px;
  }

  &-content {
    width: 100%;
    max-width: calc(100% - 356px);
    margin-left: 25px;
  }

  &-child,
  &-item {
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }
  }
}

@import '@/assets/styles/global.scss';

.Photos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  position: relative;
  $self: &;

  &-photo,
  &-create {
    border-radius: 10px;
    width: 53px;
    height: 53px;
  }

  &-photo {
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    &::before {
      content: '';
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      @extend %transition;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
      #{$self} {
        &-delete {
          transform: translateY(0);
        }
      }
    }

    &_view {
      cursor: pointer;
      &::before {
        display: none;
      }
    }

    &_chat {
      overflow: visible;
    }
  }

  &-delete {
    transform: translateY(-200%);
    @extend %transition;

    &_chat {
      position: absolute;
      top: -8px;
      right: -8px;
      transform: translateY(0);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 50;
    }
  }

  &-create {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #3499e6;
  }

  &-file {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: -99999;
  }
}

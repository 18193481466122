@import '@/assets/styles/global.scss';

.Analytics {
  &-item {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@import '@/assets/styles/global.scss';

.Fields {
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &-filters {
    display: flex;
    align-items: center;
  }

  &-title {
    margin: 0;
  }

  &-select {
    margin-left: 10px;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}

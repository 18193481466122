@import '@/assets/styles/global.scss';

.TextArea {
  position: relative;
  $self: &;

  &_xxl {
    margin-bottom: 40px;
  }
  &_xl {
    margin-bottom: 30px;
  }
  &_lg {
    margin-bottom: 25px;
  }
  &_md {
    margin-bottom: 20px;
  }
  &_sm {
    margin-bottom: 14px;
  }

  &_none {
    margin-bottom: 0;
  }

  &-wrap {
    position: relative;
  }

  &-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    z-index: 50;
    &_left {
      left: 16px;
      min-width: 18px;
    }
    &_right {
      right: 16px;
    }

    &_square {
      top: 11px;
      right: 16px;
      transform: translate(0);

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: $dark;
    }
  }

  &-label {
    display: flex;
    margin-bottom: 5px;
    font-weight: $font-weight-medium;
    font-size: $font-size-sm;
    line-height: 15px;

    &_required {
      position: relative;
      padding-left: 15px;
      &::before {
        position: absolute;
        left: 3px;
        top: -2px;
        content: '*';
        font-weight: normal;
        font-size: $font-size-required-before;
        line-height: 23px;
        letter-spacing: 0.0015em;
        color: $primary;
      }
    }
  }

  &-input {
    width: 100%;
    min-height: 42px;
    padding: 12px 15px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    font-family: 'Montserrat', sans-serif;
    resize: none;
    @extend %transition;
    color: #687283;
    &_white {
      background-color: $white;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    }
    &_default {
      background-color: #f7f7f7;
    }

    // &:focus {
    //   border-color: $text-menu;
    // }

    &::placeholder {
      color: #687283;
    }

    &[readonly] {
      &:focus {
        border-color: $light-gray;
      }
    }

    &[disabled] {
      background-color: $gray-900;
      border-color: $gray-900;
    }

    &_error {
      border: 1.5px solid $red;
      &:focus {
        border: 1.5px solid $red;
      }
    }

    &_left {
      padding-left: 43px;
    }
    &_right {
      padding-right: 43px;
    }

    &_arrow {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    &_xxl {
      min-height: 66px;
    }

    &_xl {
      min-height: 60px;
    }

    &_lg {
      min-height: 50px;
      font-size: $font-size-lg;
    }

    &_md {
      min-height: 40px;
    }

    &_sm {
      min-height: 32px;
    }

    &_errors {
      color: #e24a4a;
    }

    &_ios {
      font-size: 16px;
      line-height: 16px;
      min-height: 50px;
    }
  }

  &-eye {
    position: absolute;
    top: 50%;
    right: 18px;
    display: flex;
    padding: 0;
    transform: translateY(-50%);
  }

  &-error {
    position: absolute;
    margin-top: 5px;
    transition: all 0.5s ease;
    color: $red;
    font-size: $font-size-xsm;

    &.entering,
    &.entered {
      animation: slideError 0.5s ease-out forwards;
    }

    &.exiting {
      animation: slideError 0.5s ease-in alternate-reverse;
    }

    &.exited {
      opacity: 0;
    }
  }

  &-tooltip {
    max-width: 200px;
    z-index: 500;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    color: #ffffff;
  }
}

@keyframes slideError {
  0% {
    transform: translateX(30px);

    opacity: 0;
  }

  100% {
    transform: translateX(0);

    opacity: 1;
  }
}

@import '@/assets/styles/global.scss';

.TaskItem {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include tablet {
    flex-direction: column;
  }

  &-left {
    width: 100%;
    max-width: 40%;
  }

  &-actions {
    width: 100%;
    max-width: 60%;
  }

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3499e6;
  }

  &-desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #687283;
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-bottom {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }

  &-day {
    font-weight: 600;
    font-size: 11px;
    line-height: 160%;
    color: #687283;
    margin-right: 10px;
  }

  &-btn {
    margin-left: 10px;
    // &:nth-child(2) {
    //   margin-left: auto;
    // }
  }
}

@import '@/assets/styles/global.scss';

.Menu {
  &-list {
    display: flex;
    align-items: center;
  }

  &-item {
    margin-right: 8px;

    &:last-child {
      margin: 0;
    }
  }

  &-link {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #171e2a;
    border-radius: 6px;
    padding: 9px 6px;
    @extend %transition;
    overflow: hidden;

    &:hover,
    &_active {
      background-color: rgba(#3499e6, 0.1);
      color: #3499e6;
    }
  }

  &-title {
  }
}

@import '@/assets/styles/global.scss';

.Users {
  display: inline-flex;
  align-items: center;
  padding: 2px 20px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 6px;

  &-group {
    display: flex;
    align-items: center;
  }

  &-item {
    margin-left: -8px;
    border: 2px solid #ffffff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 8px;
    line-height: 8px;
    text-align: center;
    color: #ffffff;
    &:first-child {
      margin: 0;
    }
  }

  &-count {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #3499e6;
    margin-left: 5px;
  }
}

@import '@/assets/styles/global.scss';

.Forecast {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  @extend %transition;

  &:hover {
    background-color: #f3f9fd;
  }

  &-icon {
    margin-bottom: 18px;
    width: 40px;
    height: 40px;
  }

  &-today {
    margin-bottom: 25px;
  }

  &-day {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 5px;
  }
  &-date {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #687283;
  }
  &-el {
    margin-bottom: 20px;
    &:last-child {
      margin: 0;
    }
  }
  &-value {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
  }
  &-key {
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    color: #687283;
    margin-top: 5px;
  }
}

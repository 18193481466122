@import '@/assets/styles/global.scss';

.SoilCondition {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #171e2a;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 10px;

    @include xmobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

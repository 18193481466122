@import '@/assets/styles/global.scss';

.Title {
  font-weight: 600;
  color: #171e2a;
  margin-bottom: 20px;

  &_xxl {
    font-size: 22px;
    line-height: 22px;

    @include widescreen {
      font-size: 18px;
      line-height: 18px;
    }
  }

  &_xl {
    font-size: 20px;
    line-height: 20px;
  }

  &_lg {
    font-size: 18px;
    line-height: 20px;
  }

  &_md {
    font-size: 16px;
    line-height: 20px;
  }

  &_sm {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 18px;
  }
}

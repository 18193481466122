@import '@/assets/styles/global.scss';

.Season {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;

  display: flex;
  align-items: center;

  @include tablet {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
  }

  @include retina {
    grid-template-columns: repeat(1, 1fr);
  }

  &-el {
    width: 15%;

    @include widescreen {
      width: 20%;
    }

    @include tablet {
      width: 100%;
    }

    &:first-child {
      width: 40%;

      @include widescreen {
        width: 20%;
      }

      @include tablet {
        width: 100%;
      }
    }

    // &:last-child {
    //   display: flex;
    //   align-items: center;
    //   justify-content: flex-end;

    //   @include tablet {
    //     // grid-column: 2/-1;
    //     justify-content: flex-start;
    //   }
    // }

    &_flex {
      display: flex;
      align-items: center;
    }
  }

  &-square {
    width: 28px;
    height: 28px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  &-value {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #171e2a;
  }
  &-key {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #687283;
    margin-top: 4px;
  }

  &-btn {
    padding: 0;
    &_delete {
      margin-left: 25px;

      svg {
        path {
          @extend %transition;
        }
      }

      &:hover {
        svg {
          path {
            fill: #e24a4a;
          }
        }
      }
    }
  }
}

@import '@/assets/styles/global.scss';

.TaskView {
  &-top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &-info {
    margin-right: 10px;
    &:last-child {
      margin: 0;
    }
  }

  &-wrap {
    display: flex;
    justify-content: space-between;
  }

  &-content {
    width: 100%;
    max-width: calc(100% - 380px);
  }

  &-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #d9d9d9;
  }

  &-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #171e2a;
    margin-bottom: 6px;
  }

  &-value {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #687283;
  }

  &-chat {
    max-width: 356px;
  }
}

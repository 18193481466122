@import '@/assets/styles/global.scss';

.ListInfo {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  &-value {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #171e2a;
  }
}

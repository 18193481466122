@import '@/assets/styles/global.scss';

.FieldItem {
  padding: 15px;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-view {
    display: flex;
    align-items: center;
    margin-right: 50px;
  }

  &-data {
    margin-left: 15px;
  }

  &-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #171e2a;
  }
  &-hectares {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #687283;
    margin-top: 5px;
  }

  &-infos {
    display: flex;
    align-items: center;
  }

  &-info {
    margin-left: 10px;

    &:first-child {
      margin: 0;
    }
  }

  &-tasks {
    margin: 15px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  &-task {
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 10px;
  }

  &-link {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #3499e6;
  }

  &-text {
    margin-top: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #687283;
  }

  &-wrap {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  &-date {
    font-weight: 600;
    font-size: 11px;
    line-height: 160%;
    color: #687283;
    margin-right: 10px;
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@import '@/assets/styles/global.scss';

.Organization {
  padding: 15px;
  display: flex;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;
  }

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #171e2a;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #687283;
  }

  &-actions {
    @include tablet {
      margin-top: 12px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 10px;
    }
  }

  &-btn {
    margin-left: 10px;

    &:first-child {
      margin: 0;
    }

    @include tablet {
      margin: 0;
    }
  }
}

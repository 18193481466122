@import '@/assets/styles/global.scss';

.Select {
  position: relative;
  $self: &;

  &_xxl {
    margin-bottom: 40px;
  }
  &_xl {
    margin-bottom: 30px;
  }
  &_lg {
    margin-bottom: 25px;
  }
  &_md {
    margin-bottom: 20px;
  }
  &_sm {
    margin-bottom: 14px;
  }

  &_none {
    margin-bottom: 0;
  }

  &-wrap {
    position: relative;
  }

  &-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    z-index: 50;
    &_left {
      left: 16px;
      min-width: 18px;
    }
    &_right {
      right: 16px;
    }

    &_square {
      top: 11px;
      right: 16px;
      transform: translate(0);

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: $dark;
    }
  }

  &-label {
    display: flex;
    margin-bottom: 5px;
    font-weight: $font-weight-medium;
    font-size: $font-size-sm;
    line-height: 15px;

    &_required {
      position: relative;
      padding-left: 15px;
      &::before {
        position: absolute;
        left: 3px;
        top: -2px;
        content: '*';
        font-weight: normal;
        font-size: $font-size-required-before;
        line-height: 23px;
        letter-spacing: 0.0015em;
        color: $primary;
      }
    }
  }

  &-tooltip {
    max-width: 200px;
    z-index: 500;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    color: #ffffff;
  }

  &-basic {
    &_multi {
      .Select {
        &-el {
          &__multi {
            &-value {
              background: rgba(74, 135, 226, 0.2);
              color: #e24a4a;
              border-radius: 5px;
              &__label {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 110%;
                color: #1c6fbb;
              }
            }
          }
          &__dropdown {
            &-indicator {
              display: none;
            }
          }

          &__clear {
            &-indicator {
              padding: 0;
              color: #e24a4a;
              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
    &_default {
      #{$self} {
        &-el {
          &__control {
            background-color: #f7f7f7;
          }

          &__placeholder {
            color: #687283;
          }
        }
      }
    }

    &_white {
      #{$self} {
        &-el {
          &__control {
            background-color: $white;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
          }
        }
      }
    }

    &_ios {
      #{$self} {
        &-el {
          &__single-value,
          &__placeholder,
          &__input {
            font-size: 16px;
            line-height: 16px;
          }
          &__control {
            min-height: 50px;
          }
        }
      }
    }

    &_error {
      #{$self} {
        &-el {
          &__indicators {
            display: none;
          }
        }
      }
    }
  }

  &-el {
    &__placeholder {
      color: #687283;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
    }
    &__single {
      &-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #687283;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.4;
      }
    }
    &__control {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding: 5px 15px;
      min-height: 40px;
    }

    &__input {
      &-container {
        position: absolute;
        opacity: 0;
        z-index: -99999;
      }
    }

    &__indicators {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      line-height: 1;
    }

    &__value {
      &-container {
        overflow: hidden;
        margin-right: 15px;
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__menu {
      border: none;
      background-color: #ffffff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1),
        0px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin-top: 8px;
      top: 100%;
      position: absolute;
      width: 100%;
      z-index: 100;
      &-list {
        max-height: 150px;
        overflow-y: auto;

        /* Works on Firefox */
        * {
          scrollbar-width: thin;
          scrollbar-color: rgba(52, 153, 230, 0.1) #3499e6;
        }
        /* Works on Chrome, Edge, and Safari */
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: rgba(52, 153, 230, 0.1);
        }
        &::-webkit-scrollbar-thumb {
          background-color: #3499e6;
          border-radius: 20px;
          // border: 3px solid #3499e6;
        }
      }
    }

    &__option {
      padding: 15px 20px;
      border-bottom: 1px solid #eeeeee;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #687283;
      cursor: pointer;

      &--is-focused {
      }

      &--is-selected {
        color: #3499e6;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

@keyframes slideError {
  0% {
    transform: translateX(30px);

    opacity: 0;
  }

  100% {
    transform: translateX(0);

    opacity: 1;
  }
}
